<script setup>
import OperationalHistoryData from './OperationalHistoryData.vue';

import { onMounted, ref } from 'vue';

const props = defineProps([
  'id',
  'hospitalName'
])

const emit = defineEmits(['hideHistory'])

import ApiRequest from '@/services/ApiRequest.js'

const api = new ApiRequest()

const history = ref(false)
const showObs = ref(false)

onMounted(async () => {
  try {
    const resp = await api.get(`/job_log/${props.id}`)
    if (resp.status === 200) {
      history.value = await resp.json()
    }
  } catch (error) {
    console.error("Error:", error);
    throw new Error("An error occurred while making the GET request.");
  }
})

function toggle_obs() {
  showObs.value = !showObs.value
}
</script>

<template>
  <div class="conteudo">
    <div class="caixa-branca">
      <div v-if="history === false" class="button is-primary is-outlined is-loading" style="border: none; height: 100%;">
        carregando....
      </div>
      <template v-else>
        <div class="titulo">HISTÓRICO OPERACIONAL</div>
        <div class="hospital-nome">
          <b>{{ hospitalName }}</b>
          <div class="button voltar" @click="$emit('hideHistory')">Voltar</div>
        </div>
        <div class="table-head">
          <div class="index">#</div>
          <div class="data">Data</div>
          <div class="eficiencia">Eficiencia</div>
          <div class="status">Status</div>
          <div class="observacao-mobile">Obs</div>
          <div class="observacao-desktop">Observação</div>
        </div>
        <div class="table-body">
          <OperationalHistoryData v-for="(data, index) in history.logs" :key="data" :index="index + 1" :date="data.date"
            :efficiency="data.efficiency" :observations="data.observations" class="table-row">
          </OperationalHistoryData>
        </div>
      </template>
    </div>
  </div>
</template>

<style>
.conteudo {
  width: 100%;
  display: grid;
}

.caixa-branca {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  width: 342px;
}

.titulo {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  background-color: rgb(167, 138, 11);
  border-radius: 5px;
}

.hospital-nome {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}

.voltar {
  width: min-content;
  margin: 5px 0;
}

.table-head {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 3fr 2fr;
  grid-template-rows: 50px;
  align-items: center;
  border-bottom: solid 1px #4a4a4a6e;
  font-weight: bold;
  text-align: center;
}

.observacao-desktop {
  display: none;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

@media screen and (min-width: 769px) {
  .caixa-branca {
    margin-top: 0px;
    width: 1200px;
  }

  .table-head {
    grid-template-columns: 1fr 4fr 4fr 3fr 70%;
  }

  .observacao-mobile {
    display: none;
  }

  .observacao-desktop {
    display: block;
  }
}
</style>
