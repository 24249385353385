<script setup>
import HeaderSection from '@/components/HeaderSection.vue'
import Carousel from '@/components/Carousel.vue'
import Slide from '@/components/Slide.vue'
import CarouselItem from '@/components/CarouselItem.vue'
import GlobalResult from '@/components/GlobalResult.vue'
import MobileHospitalSelect from '@/components/MobileHospitalSelect.vue'
import OperationalHistory from '@/components/OperationalHistory.vue'

import ApiRequest from '@/services/ApiRequest.js'

import { onBeforeMount, ref } from 'vue'

const hospitais = ref([])
const hospital_groups = ref([]);
var hospital_list = ref([])
const canShowOperationalHistory = ref(false)
const operationalHistoryId = ref()
const operacionalHistoryName = ref()

const api = new ApiRequest()

const generate_hospitals_group = () => {
  hospital_groups.value = []
  hospital_list.value = []
  // agrupa os hospitais de três em três em uma lista, montando uma lista de listas
  hospitais.value.forEach((data, indice) => {
    // agrupa dois hospitais na lista
    if ((indice + 1) % 3 !== 0) {
      hospital_list.value.push(data)
    }
    // agrupa o ultimo, adiciona a lista de listas e zera a sub-lista
    else if ((indice + 1) % 3 === 0) {
      hospital_list.value.push(data)
      hospital_groups.value.push(hospital_list.value)
      hospital_list.value = []
    }
    // na ultima iteração inclui a sub-lista na lista caso tenha algum valor na sub-lista
    if ((indice + 1 == hospitais.value.length) && (hospital_list.value.length > 0)) {
      hospital_groups.value.push(hospital_list.value)
    }
  })
}

const getHospitals = async () => {
  try {
    const resp = await api.get('/panels')
    if (resp.status === 200) {
      hospitais.value = await resp.json()
    }
  } catch (error) {
    console.error(error)
  }
  generate_hospitals_group()
}

function defineOperationalHistory(id) {
  operationalHistoryId.value = id.id
  canShowOperationalHistory.value = true
  operacionalHistoryName.value = id.name
}

function hideHistory() {
  canShowOperationalHistory.value = false
}
onBeforeMount(async () => {
  getHospitals()
})

setInterval(async () => {
  getHospitals()
}, 60 * 60000)
</script>

<template>
  <div class="pb-5">
    <HeaderSection />
  </div>

  <div v-if="hospital_groups.length === 0" class="button is-outlined is-loading"
    style="border: none; height: 100%; width: 100%; background-color: transparent; text-align: center;">
    Carregando...
  </div>
  <div v-else="hospital_groups" class="content-display">
    <GlobalResult :hospitais="hospitais"></GlobalResult>
    <OperationalHistory v-if="canShowOperationalHistory" class="operational-history" :id="operationalHistoryId"
      :hospitalName="operacionalHistoryName" @hide-history="() => { hideHistory() }">
    </OperationalHistory>
    <div v-else>
      <MobileHospitalSelect class="mobile-hospitals" :hospitals="hospitais"
        @show-history="(id) => { defineOperationalHistory(id) }"></MobileHospitalSelect>
      <Carousel class="carousel" v-slot="{ currentSlide }">
        <Slide v-for="(hospital_group, index) in hospital_groups" :key="index">
          <div v-show="currentSlide === index + 1" class="slide-info">
            <CarouselItem :hospital_group="hospital_group" :key="index"
              @show-history="(id) => { defineOperationalHistory(id) }" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<style>
:root {
  height: 100%;
  background-image: url(/public/ted_maos.png);
}

:root::-webkit-scrollbar {
  display: none;
}

.content-display {
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: 290px 1220px;
  grid-template-rows: 1fr;
}

.mobile-hospitals {
  display: none;
}

.carousel {
  position: relative;
  max-height: 100vh;
  display: block;
  justify-content: center;
}

.slide-info {
  position: absolute;
  width: 1200px;
  max-height: 100%;
  height: 100%;
}

@media (max-width: 1000px) {
  :root {
    background-position: center center;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .operational-history {
    padding: 1rem 0px;
  }

  .content-display {
    display: flex;
    flex-direction: column;
  }

  .carousel {
    display: none;
  }

  .mobile-hospitals {
    display: flex;
  }
}
</style>
