<script setup>
const props = defineProps([
  'hospital'
])

const emits = defineEmits(['showHistory'])

// validação dos campos
for (const attribute in props.panel) {
  for (const inner in props.panel[attribute]) {
    if (props.panel[attribute][inner] === null) {
      props.panel[attribute][inner] = 'ERROR'
    }
  }
}

const data = props.hospital;

const hospitalHistoryObj = { 'id': data.id, 'name': data.panel.client.name }
</script>

<template>
  <div class="container is-fullhd box py-3 content is-small">
    <div class="columns is-0 is-variable">
      <div class="column">
        <div style="margin-top: 9px;">
          <div class="column-gap">
            <div class="hospital-name content is-normal"><b>{{ data.panel.client.name }}</b></div>
            <div class="hospital-info">Local: <b>{{ data.panel.client.city }}</b></div>
            <div class="hospital-info">Monitoramento: <b class="link"><a target="_blank"
                  :href="data.panel.client.monitoring">
                  Clique
                  Aqui</a></b>
            </div>
            <div class="hospital-info">Histórico Operacional: <b class="link"
                @click="$emit('showHistory', hospitalHistoryObj)">Clique Aqui</b>
            </div>
            <div class="hospital-info">Consumo mensal médio historico:
              <b>{{ data.panel.client.monthly_consumption }} m³</b>
            </div>
            <div class="hospital-info">Parâmetro mensal médio histórico:
              <b>{{ data.panel.client.monthly_parameter }}</b>
            </div>
          </div>
        </div>

      </div>
      <div class="column">
        <div class="data-icons">
          <div class="dots" style="border: none;"></div>
          <img class="image is-64x64" src="@/assets/hospital.png" />
          <div class="dots"></div>
        </div>
        <div class="column-gap">
          <div class="hospital-data">
            <div class="data-description">
              Eficiência (R$) no Mês:
            </div>
            <b class="data-number">{{ data.panel.efficiency.in_cash }}%</b>
            <div class="alarm" :class="data.panel.efficiency.in_cash >= 3 ? 'green-alarm' : 'red-alarm'"></div>
          </div>
          <div class="hospital-data">
            <div class="data-description">
              Eficiência (m³) no Mês:
            </div>
            <b class="data-number">{{ data.panel.efficiency.in_volume }}%</b>
            <div class="alarm" :class="data.panel.efficiency.in_volume >= 3 ? 'green-alarm' : 'red-alarm'"></div>
          </div>
          <div class="hospital-data">
            <div class="data-description">
              Projeção vol. economizado:
            </div>
            <b class="data-number"> {{ data.panel.efficiency.volume_saved }} m³</b>
            <div class="alarm" :class="data.panel.efficiency.volume_saved > 0 ? 'green-alarm' : 'red-alarm'" />
          </div>
          <div class="hospital-data">
            <div class="data-description">
              Autonomia operacional:
            </div>
            <b class="data-number">{{ data.panel.efficiency.operational_autonomy === 'ERROR' ?
              data.panel.efficiency.operational_autonomy :
              data.panel.efficiency.operational_autonomy + (data.panel.efficiency.operational_autonomy === 1
                ?
                ' dia' : ' dias') }}</b>
            <div class="alarm" :class="data.panel.efficiency.operational_autonomy >= 2 ? 'green-alarm' : 'red-alarm'" />
          </div>
          <div class="hospital-data min-aut">
            <div class="data-description">
              Eficiência (R$) Acumulada:
            </div>
            <b class="data-number">{{ data.panel.efficiency.accumulated }}%</b>
            <div class="alarm" :class="data.panel.efficiency.accumulated > 0 ? 'green-alarm' : 'red-alarm'"></div>
          </div>
        </div>

      </div>
      <div class="column">
        <div class="data-icons">
          <div class="dots"></div>
          <img class="image is-64x64" src="@/assets/maintence_icon.png" />
          <div class="dots" style="border-color: rgb(0, 191, 99);"></div>
        </div>
        <div class="column-gap">
          <div class="repair-data">
            <div class="data-description">
              Reparos realizados no mês:
            </div>
            <b class="data-number">{{ data.panel.repair.carried_out }}</b>
          </div>
          <div class="repair-data">
            <div class="data-description">
              Vazamentos detectados:
            </div>
            <b class="data-number">{{ data.panel.repair.leaks_detected }}</b>
          </div>
          <div class="repair-data">
            <div class="data-description">
              Vazamentos corrigidos:
            </div>
            <b class="data-number">{{ data.panel.repair.leaks_fixed }}</b>
          </div>
          <div class="repair-data">
            <div class="data-description">
              OSs atendidas:
            </div>
            <b class="data-number">{{ data.panel.repair.os }}</b>
          </div>
          <div class="repair-data">
            <div class="data-description">
              SLA Monitoramento:
            </div>
            <b class="data-number">{{ data.panel.repair.sla }}%</b>
            <div class="alarm" :class="data.panel.repair.sla >= 3 ? 'green-alarm' : 'red-alarm'"></div>
          </div>
        </div>

      </div>
      <div class="column">
        <div class="data-icons">
          <div class="dots" style="border-color: rgb(0, 191, 99);"></div>
          <img class="image is-64x64 face" src="@/assets/water_icon.png" />
          <div class="dots" style="border: none;"></div>
        </div>
        <div class="column-gap">
          <div class="water-data">
            <div class="esg-data-description">
              Água Economizada Acumulada:
            </div>
            <b class="esg-number">{{ data.panel.esg.saved_water }} m³</b>
          </div>
          <div class="water-data">
            <div class="esg-data-description">
              Efluiente Evitado Acumulado:
            </div>
            <b class="esg-number">{{ data.panel.esg.avoided_effluent }} m³</b>
          </div>
          <div class="water-data">
            <div class="esg-data-description">
              CO2 Evitado Acumulado (TON.):
            </div>
            <b class="esg-number">{{ data.panel.esg.avoided_co2 }} m³</b>
          </div>
          <div class="water-data">
            <div class="esg-data-description">
              Eficiência m³ Acumulada:
            </div>
            <b class="esg-number">{{ data.panel.esg.acumulated_efficiency }}%</b>
          </div>
          <div class="water-data">
            <div class="esg-data-description">
              Equivalente em Casas (Acumulado)
            </div>
            <b class="esg-number">{{ data.panel.esg.houses_equivalence }}</b>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.box {
  width: 1200px;
}



.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 300px;
  max-width: 300px;
}

.column-gap {
  padding-left: 13px;
  padding-right: 13px;
}

.data-icons {
  display: flex;
  justify-content: stretch;
  margin-bottom: 15px;
}

.dots {
  height: 38px;
  width: -webkit-fill-available;
  border-bottom: 5px dotted rgb(0, 37, 63);
  background-color: white;
  margin-left: 2px;
  margin-right: 2px;

}

.hospital-name {
  padding-top: 8px;
  margin-bottom: 15px;
  text-align: center;
  color: white;
  background-color: rgb(7, 106, 178);
  border-radius: 5px;
  height: 40px;
}

.hospital-info {
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  margin-top: 2px;
  background-color: rgb(167, 138, 11);
  border-radius: 5px;
}

.hospital-data {
  display: flex;
  padding-left: 4px;
  color: white;
  margin-top: 2px;
  background-color: rgb(7, 106, 178);
  border-radius: 5px;
}

.min-aut {
  color: black;
  background-color: rgb(172, 184, 192);

}

.repair-data {
  display: flex;
  padding-left: 4px;
  color: white;
  margin-top: 2px;
  background-color: rgb(0, 37, 63);
  border-radius: 5px;
}

.water-data {
  display: flex;
  padding-left: 4px;
  color: white;
  margin-top: 2px;
  background-color: rgb(0, 191, 99);
  border-radius: 5px;
}

.data-description {
  width: 75%;
}

.esg-data-description {
  width: 75%;
}

.data-number {
  width: 25%;
  text-align: center;
}

.esg-number {
  width: 25%;
  text-align: center;
}


.alarm {
  position: absolute;
  margin-left: 262px;
  margin-top: 3px;
  width: 13px;
  height: 12px;
  border-radius: 50%;
}

.red-alarm {
  background-color: red;
}

.green-alarm {
  background-color: rgb(48, 219, 76);
}

.yellow-alarm {
  background-color: yellow;
}

.link,
.link a {
  color: rgb(0, 37, 63);
}

.link:hover,
.link a:hover {
  color: rgb(0, 191, 99);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .dots {
    border: none;
  }

  .container {
    max-width: 85%;
  }

  .columns {
    max-width: 85%;
  }

  .box {
    width: 342px;
  }

  .column-gap {
    padding-left: 5px;
    padding-right: 5px;
  }

  .alarm {
    margin-left: 288px;
  }

}
</style>