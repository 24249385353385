<script setup>

</script>

<template>
    <header class="py-5">
        <div id="desktop-header">
            <img class="rede_dor" src="@/assets/rede_dor.png" />
            <div class="title">
                <b class="content is-normal" style="color: white;">PROJETO DE EFICIÊNCIA HÍDRICA - RDSL (SEA - SISTEMA DE
                    ECONOMIA DE ÁGUA)</b>
            </div>
            <img class="ted_logo" alt="tdrr-logo" src="@/assets/TeD_logo.png" width="150" height="65" />
        </div>
    </header>
</template>

<style scoped>
header {
    width: 100%;
    background-color: white;
}

#desktop-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rede_dor {
    height: 50px;
    width: 170px;
    align-self: center;
}

.ted_logo {
    height: 50px;
    width: 170px;
    align-self: center;
}

.title {
    display: grid;
    text-decoration-color: white;
    text-align: center;
    align-content: center;
    width: 60%;
    height: 40px;
    margin-bottom: 0px;

    background: rgb(7, 106, 178);
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .rede_dor {
        display: none;
    }

    .ted_logo {
        display: none;
    }

    .title {
        width: 85%;
        height: fit-content;
        padding: 5px;
    }
}
</style>

