<script setup>
import { ref } from 'vue'

import Hospital from './Hospital.vue';

const props = defineProps([
  'hospitals'
])

const emit = defineEmits('showHistory')

function showHistoryEmit(id) {
  emit('showHistory', id)
}

const selected_hospital = ref(0)

</script>

<template>
  <div class="hospital-select">
    <div class="control has-icons-left">
      <div class="select">
        <select v-model="selected_hospital" style="color: rgb(7, 106, 178);">
          <option selected :value="0">SELECIONE SUA UNIDADE</option>
          <option v-for="(data, index) in hospitals" :value="index">{{ data.panel.client.name }}</option>
        </select>
      </div>
      <div class="icon is-small is-left">
        <i class="fas fa-clinic-medical" style="color: rgb(7, 106, 178);"></i>
      </div>
    </div>
    <template v-for="(hospital, index) in hospitals">
      <Hospital v-if="index === selected_hospital" :hospital="hospital" @show-history="(id) => {
        showHistoryEmit(id)
      }"></Hospital>
    </template>

  </div>
</template>

<style>
.hospital-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.control {
  width: 342px;
  margin: 10px 0px;
}

.select {
  width: 100%;

}

select {
  width: 100%;
  font-weight: bold;
}
</style>