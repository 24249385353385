<script setup>
import { ref } from 'vue';

const props = defineProps([
  'index',
  'date',
  'efficiency',
  'observations'
])

const showObs = ref(false)

function toggle_obs() {
  showObs.value = !showObs.value
}

</script>

<template>
  <div class="table-row" :class="showObs ? '' : 'collapsed'" @click="toggle_obs">
    <div class="index">{{ index }}</div>
    <div class="data">{{ date }}</div>
    <div class="eficiencia">
      {{ efficiency }}%
    </div>
    <div class="status">
      <div class="alarm" :class="efficiency < 0 ? 'vermelho' : efficiency >= 10 ? 'verde' : 'amarelo'">
      </div>
    </div>
    <div class="obs">
      <div class="obs-icon" :class="showObs ? 'open' : ''">
        <i class="fas fa-caret-down"></i>
      </div>
    </div>
    <Transition name="obsFade">
      <div v-if="showObs" class="obs-text">{{ observations }}</div>
    </Transition>
    <div class="obs-desktop">{{ observations }}</div>
  </div>
</template>
<style>
.table-row {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 3fr 2fr;
  grid-template-rows: 50px 100px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0px;
  border-top: solid 1px #4a4a4a6e;
}

.table-row.collapsed {
  grid-template-rows: 50px 0px;
}

.eficiencia {
  justify-self: center;
}

.status {
  display: flex;
  justify-content: center;
}

.alarm {
  width: 13px;
  height: 12px;
  border-radius: 50%;
}

.vermelho {
  background-color: red;
}

.amarelo {
  background-color: yellow;
}

.verde {
  background-color: rgb(48, 219, 76);
}

.obs-icon {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  width: auto;
  display: flex;
  justify-self: end;
  justify-content: center;
  align-items: center;
}

.obs-icon.open {
  transform: rotate(180deg);
}

.obs-text {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 6;
  padding: 7px;
  padding-top: 0px;
  text-align: justify;
  font-size: 0.75rem;
}

.obs-desktop {
  display: none;
}

.obsFade-move,
.obsFade-enter-active,
.obsFade-leave-active {
  transition: transform 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  transition: opacity 0.1s cubic-bezier(0.55, 0, 0.1, 1);
}

.obsFade-enter-from,
.obsFade-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}

@media screen and (min-width: 769px) {
  .table-row {
    grid-template-rows: minmax(50px, max-content);
    grid-template-columns: 1fr 4fr 4fr 3fr 70%;
  }

  .obs,
  .obs-text {
    display: none;
  }

  .obs-desktop {
    display: block;
    text-align: left;
    font-size: 0.75rem;
  }
}
</style>
