<script setup>
import { onMounted, ref } from 'vue';

const getSlideCount = ref(null);
const currentSlide = ref(1);
const timeoutDuration = ref(45000)

const nextSlide = () => {
  if (currentSlide.value === getSlideCount.value) {
    currentSlide.value = 1;
    return;
  }
  currentSlide.value += 1;
}

const previousSlide = () => {
  if (currentSlide.value === 1) {
    currentSlide.value = getSlideCount.value;
    return;
  }
  currentSlide.value -= 1;
}

const autoPlay = () => {
  setInterval(() => {
    nextSlide();
  }, timeoutDuration.value);
}

onMounted(() => {
  getSlideCount.value = document.querySelectorAll(".slide").length;
})

autoPlay();
</script>

<template>
  <div class="carousel">
    <div class="slide-control">
      <div class="previous">
        <div class="previous-icon" @click="previousSlide()">
          <i class="fas fa-chevron-circle-left"></i>
        </div>
      </div>
      <div class="next">
        <div class="next-icon" @click="nextSlide()">
          <i class="fas fa-chevron-circle-right"></i>
        </div>
      </div>
    </div>

    <slot :currentSlide="currentSlide" />
  </div>
</template>

<style>
.slide-control {
  position: absolute;

  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 661px;
  align-items: center;
}

.previous,
.next {
  transition: opacity 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  display: flex;
  color: #363636;
  width: -webkit-fill-available;
  max-width: 17%;
  height: 100%;
  align-items: center;
  opacity: 0;
  z-index: 1;
}

.previous:hover,
.next:hover {
  opacity: 1;
}

.previous-icon {
  margin-left: 7px;
  font-size: 40px;
  cursor: pointer;
}

.next {
  justify-content: end;
}

.next-icon {
  margin-right: 17px;
  justify-self: flex-end;
  font-size: 40px;
  cursor: pointer;
}
</style>