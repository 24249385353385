<script setup>
import { defineEmits } from 'vue';

import Hospital from '@/components/Hospital.vue'

const emit = defineEmits(['showHistory'])

function showHistoryEmit(id) {
    emit('showHistory', id)
}

const props = defineProps([
    'hospital_group'
])
</script>
<template>
    <div class="item" style="border: 5px solid transparent">
        <Hospital v-for="hospital in props.hospital_group" :hospital="hospital"
            @show-history="(id) => { showHistoryEmit(id) }" />

    </div>
</template>
<style></style>