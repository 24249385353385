const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : "https://api.tdrr.com.br:8443";

class APIRequest {
  constructor() {
    this.baseURL = baseURL;
  }

  async get(endpoint) {
    const url = `${this.baseURL}${endpoint}`;
    const options = {
      method: "GET",
    };
    try {
      const response = await fetch(url, options);
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while making the GET request.");
    }
  }
}

export default APIRequest;
