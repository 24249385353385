<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';

const props = defineProps(['hospitais'])

const globalResult = ref({})

const totalEconomizadoOnProblem = ref(false)
const eficienciaTotalVolumeOnProblem = ref(false)
const eficienciaTotalFinanceiraOnProblem = ref(false)
const equivalenciaEmCasasOnProblem = ref(false)

async function calcularResultadoGlobal(hospitais) {
  let totalEconomizado = 0
  let eficienciaTotalVolume = 0
  let eficienciaTotalFinanceira = 0
  let equivalenciaEmCasas = 0
  let totalDeUnidadesAtivas = hospitais.length

  // Passa por cada hospital e soma seus dados
  for (let hospital in hospitais) {
    if (typeof hospitais[hospital].panel.esg.saved_water === 'number') {
      totalEconomizado += hospitais[hospital].panel.esg.saved_water
    }
    else if (typeof hospitais[hospital].panel.esg.saved_water === 'string') {
      totalEconomizadoOnProblem.value = true
    }
    if (typeof hospitais[hospital].panel.esg.acumulated_efficiency === 'number') {
      eficienciaTotalVolume += hospitais[hospital].panel.esg.acumulated_efficiency
    }
    else if (typeof hospitais[hospital].panel.esg.acumulated_efficiency === 'string') {
      eficienciaTotalVolumeOnProblem.value = true
    }
    if (typeof hospitais[hospital].panel.efficiency.accumulated === 'number') {
      eficienciaTotalFinanceira += hospitais[hospital].panel.efficiency.accumulated
    }
    else if (typeof hospitais[hospital].panel.efficiency.accumulated === 'string') {
      eficienciaTotalFinanceiraOnProblem.value = true
    }
    if (typeof hospitais[hospital].panel.esg.houses_equivalence === 'number') {
      equivalenciaEmCasas += hospitais[hospital].panel.esg.houses_equivalence
    }
    else if (typeof hospitais[hospital].panel.esg.houses_equivalence === 'string') {
      equivalenciaEmCasasOnProblem.value = true
    }
  }

  // Calcula a média dos valores 
  eficienciaTotalVolume = eficienciaTotalVolume / totalDeUnidadesAtivas
  eficienciaTotalFinanceira = eficienciaTotalFinanceira / totalDeUnidadesAtivas

  // Atribui os resultados ao globalResult
  globalResult.value.totalEconomizado = typeof totalEconomizado === 'number' ? totalEconomizado.toFixed(2) : totalEconomizado
  globalResult.value.eficienciaTotalVolume = typeof eficienciaTotalVolume === 'number' ? eficienciaTotalVolume.toFixed(2) : eficienciaTotalVolume
  globalResult.value.eficienciaTotalFinanceira = typeof eficienciaTotalFinanceira === 'number' ? eficienciaTotalFinanceira.toFixed(2) : eficienciaTotalFinanceira
  globalResult.value.equivalenciaEmCasas = equivalenciaEmCasas
  globalResult.value.totalDeUnidadesAtivas = totalDeUnidadesAtivas
}

onBeforeMount(async () => {
  await calcularResultadoGlobal(props.hospitais)
})

</script>

<template>
  <div class="conteudo content is-small" style="margin-bottom: 0px;">
    <div class="caixa">
      <div v-if="globalResult === false">carregando</div>
      <template v-else>
        <div class="titulo" style="margin-bottom: 2px;">
          RESULTADO GLOBAL
        </div>
        <div
          :title="totalEconomizadoOnProblem ? 'Houve algum erro ao tentar obter dados do total economizado de alguma planilha.' : ''"
          class="linhas">
          <div class="descricao">TOTAL ECONOMIZADO:</div>
          <div class="numero"><strong>{{ globalResult.totalEconomizado }} m³{{ totalEconomizadoOnProblem ? '*' : ''
          }}</strong></div>
        </div>
        <div
          :title="eficienciaTotalVolumeOnProblem ? 'Houve algum erro ao tentar obter dados da Eficiencia Total Volume de alguma planilha.' : ''"
          class="linhas">
          <div class="descricao">EFICIÊNCIA TOTAL VOLUME:</div>
          <div class="numero"><strong>{{ globalResult.eficienciaTotalVolume }} %{{ eficienciaTotalVolumeOnProblem ? '*' :
            '' }}</strong></div>
        </div>
        <div
          :title="eficienciaTotalFinanceiraOnProblem ? 'Houve algum erro ao tentar obter dados da Eficiencia Total Financeira de alguma planilha.' : ''"
          class="linhas">
          <div class="descricao">EFICIÊNCIA TOTAL FINANCEIRA:</div>
          <div class="numero"><strong>{{ globalResult.eficienciaTotalFinanceira }} %{{ eficienciaTotalFinanceiraOnProblem
            ? '*' : '' }}</strong></div>
        </div>
        <div
          :title="equivalenciaEmCasasOnProblem ? 'Houve algum erro ao tentar obter dados da Equivalência em Casas de alguma planilha.' : ''"
          class="linhas">
          <div class="descricao">EQUIVALÊNCIA EM CASAS:</div>
          <div class="numero"><strong>{{ globalResult.equivalenciaEmCasas }}{{ equivalenciaEmCasasOnProblem ? '*' : ''
          }}</strong></div>
        </div>
        <div class="linhas">
          <div class="descricao">TOTAL DE UNIDADES ATIVAS:</div>
          <div class="numero"><strong>{{ globalResult.totalDeUnidadesAtivas }}</strong></div>
        </div>
      </template>
    </div>
  </div>
</template>

<style>
.conteudo {
  width: 100%;
  display: grid;
  justify-content: center;
}

.caixa {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
  color: #4a4a4a;
  display: grid;
  padding: 1.25rem;
  width: 342px;
}

.titulo {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  background-color: rgb(167, 138, 11);
  border-radius: 5px;
}

.linhas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 35px;
  align-items: center;
  margin: 2px 0px;
}

.descricao {
  font-weight: bold;
  display: flex;
  background-color: rgb(7, 106, 178);
  color: #fff;
  width: 225px;
  height: 100%;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.numero {
  height: 27px;
  width: 77px;
  justify-self: center;
  border-top: solid 1px rgb(7, 106, 178);
  border-right: solid 1px rgb(7, 106, 178);
  border-bottom: solid 1px rgb(7, 106, 178);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .conteudo {
    align-self: center;
    justify-self: center;
    margin-top: 4px;
    height: 657px;
    display: grid;
    justify-content: center;
  }

  .caixa {
    width: 265px;
  }

  .titulo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    font-size: 1.25rem;
  }

  .linhas {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35px 35px;
    align-items: center;
    margin: 2px 0px;
  }

  .descricao {
    font-size: 14px;
  }

  .numero {
    height: 50px;
    width: 210px;
    border-top: none;
    border-left: solid 1px rgb(7, 106, 178);
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    font-size: 25px;
  }

}
</style>